// create user related store
import { defineStore, storeToRefs } from "pinia";
import { useOrgStore } from "./organization";

import type { LoginForm } from "@/composables/useURM";
import type { OrgProfile } from "@/types/organizations";
import type { User } from "@/types/user";

// create user store
export const useUserStore = defineStore(
	"User",
	() => {
		const { login, logout } = useURM();
		const { viewOrganization } = useOrgMApi();
		const { userAuth } = useURMApi();
		const { setOrganization } = useOrgStore();
		const { organization } = storeToRefs(useOrgStore());
		// data
		const user = ref<User | null>();
		const userOrganizationProfile = ref<OrgProfile>();

		const isLoggedIn = computed(() => !!user.value);
		// async|logic
		// function for user login
		async function userLogin(data: LoginForm, sso = false) {
			if (sso === false) {
				return Promise.reject(new Error("Cannot login without SSO from this platform."));
			}
			try {
				const userInfo = await login(data, true);
				user.value = userInfo;
				if (user.value.organization_id) {
					await setOrganization(user.value.organization_id);
				}
				return "ok";
			} catch (error: any) {
				if (error.data) {
					return Promise.reject(new Error(error.data.error.message));
				}
				return Promise.reject(error);
			}
		}

		// function for user logout
		async function userLogout() {
			await logout();
			sessionStorage.clear();
			user.value = null;
			organization.value = null;
		}

		// fetch user info when there is token in cookie
		// user doesn't need to login again
		async function getUserInfo() {
			const { response } = await userAuth();
			user.value = (response?.data as any).user;

			if (user.value?.organization_id) {
				const { response: orgRes } = await viewOrganization(user.value?.organization_id as string);
				const res: any = orgRes?.data;
				userOrganizationProfile.value = res?.profile as OrgProfile;
			}

			if (user.value) {
				return "Success";
			} else {
				Promise.reject(new Error("Failed to retrieve user info."));
			}
		}

		return {
			user,
			isLoggedIn,
			userOrganizationProfile,
			userLogin,
			userLogout,
			getUserInfo,
		};
	},
	{
		persist: {
			storage: persistedState.sessionStorage,
		},
	}
);
